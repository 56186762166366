<template>
  <div>
    <!--begin::Notice-->
    <div
      class="alert alert-custom alert-white alert-shadow gutter-b"
      role="alert"
    >
      <div class="alert-icon alert-icon-top">
        <span class="svg-icon svg-icon-3x svg-icon-primary mt-4">
          <!--begin::Svg Icon-->
          <inline-svg src="media/svg/icons/Communication/Share.svg" />
          <!--end::Svg Icon-->
        </span>
      </div>
      <div class="alert-text">
        <p>
          Введите специалистов вашей организации. Они будут иметь доступ к
          выдаче заключений.
        </p>
      </div>
    </div>
    <!--end::Notice-->

    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Специалисты</h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center">
                <span data-toggle="tooltip" class="example-toggle"></span>
                <span data-toggle="tooltip" class="example-copy"></span>
              </div>
            </div>
          </div>
          <div class="card-body" data-app>
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="staff"
                :search="search"
                sort-by="id"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title></v-toolbar-title>
                    <v-text-field
                      v-model="search"
                      append-icon="search"
                      label="Поиск"
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Добавить специалиста
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="4" sm="3" md="2">
                                <v-text-field
                                  v-model="editedItem.id"
                                  label="ID"
                                  readonly
                                ></v-text-field>
                              </v-col>
                              <v-col cols="8" sm="9" md="10">
                                <v-text-field
                                  v-model="editedItem.username"
                                  label="Логин"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="editedItem.first_name"
                                  label="Имя"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="editedItem.last_name"
                                  label="Фамилия"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="close">
                            Отменить
                          </v-btn>
                          <v-btn color="blue darken-1" text @click="save">
                            Сохранить
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="headline"
                          >Вы уверены, что хотите удалить
                          пользователя?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete"
                            >Отмена</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItemConfirm"
                            >Да</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
                <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize"> Сбросить </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "org-staff",
  components: {},
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      search: "",
      headers: [
        {
          text: "ID",
          align: "left",
          sortable: true,
          value: "id"
        },
        {
          text: "Логин",
          sortable: true,
          value: "username"
        },
        {
          text: "Имя",
          sortable: true,
          value: "first_name"
        },
        {
          text: "Фамилия",
          sortable: true,
          value: "last_name"
        },
        /* {
          text: "Роль",
          sortable: true,
          value: "groupNames"
        }, */
        {
          text: "Действия",
          value: "actions",
          sortable: false
        }
      ],
      staff: [],
      roles: [],
      editedIndex: -1,
      editedItem: {
        id: "",
        username: "",
        first_name: "",
        last_name: "",
        groups: "",
        groupNames: ""
      },
      defaultItem: {
        id: "",
        username: "",
        first_name: "",
        last_name: "",
        groups: "",
        groupNames: ""
      }
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    formTitle() {
      return this.editedIndex === -1 ? "Новый сотрудник" : "Редактировать";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  created() {
    this.initialize();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "База данных" },
      { title: "Специалисты" }
    ]);

    this.$nextTick(() => {
      const hljs = this.$el.querySelectorAll(".hljs");
      hljs.forEach(hl => {
        hl.classList.remove("hljs");
        hl.classList.add(`language-${hl.classList[1]}`);
      });
    });
  },
  destroyed() {},
  methods: {
    loadUsers() {
      return new Promise(resolve => {
        ApiService.get("api/v1/users").then(({ data }) => {
          //   console.log(data.results);
          this.staff = data.results;
          this.staff.forEach(user => {
            this.getRoleNames(user);
          });
          this.editedItem.id = this.getLastId();
          resolve(data);
        });
      }).catch(({ response }) => {
        console.error(response);
      });
    },

    getRoleNames(user) {
      var groups = "";
      if (user.groups.length) {
        user.groups.forEach(gr => {
          if (groups !== "") groups += ", ";
          groups += this.roles.find(role => {
            return role.id === gr;
          }).name;
        });
      }
      user.groupNames = groups;
    },

    initialize() {
      return new Promise(resolve => {
        ApiService.get("api/v1/groups").then(({ data }) => {
          //  console.log(data.results);
          var roles = [];
          data.results.forEach(function(role) {
            roles.push({
              id: role.id,
              name: role.name,
              description: role.description,
              roleId: role.id
            });
          });
          this.roles = roles;
          this.loadUsers();
          resolve(data);
        });
      }).catch(({ response }) => {
        console.error(response);
      });
    },

    getLastId() {
      if (this.staff.length) {
        return Math.max(...this.staff.map(el => el.id)) + 1;
      } else {
        return 1;
      }
    },

    editItem(item) {
      this.editedIndex = this.staff.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.staff.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.staff.splice(this.editedIndex, 1);
      return new Promise(resolve => {
        ApiService.delete("api/v1/users/" + this.editedItem.id + "/").then(
          ({ data }) => {
            this.closeDelete();
            resolve(data);
          }
        );
      }).catch(({ response }) => {
        console.error(response);
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.defaultItem.id = this.getLastId();
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.defaultItem.id = this.getLastId();
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        return new Promise(resolve => {
          ApiService.patch("api/v1/users/" + this.editedItem.id + "/", {
            username: this.editedItem.username,
            first_name: this.editedItem.first_name,
            last_name: this.editedItem.last_name,
            groups: this.editedItem.groups,
            org_id: [this.$store.getters.currentUser.org.id]
          }).then(({ data }) => {
            Object.assign(this.staff[this.editedIndex], this.editedItem);
            //run role name getter
            this.getRoleNames(this.staff[this.editedIndex]);
            this.close();
            resolve(data);
          });
        }).catch(({ response }) => {
          console.error(response);
        });
      } else {
        return new Promise(resolve => {
          ApiService.post("api/v1/users/", {
            username: this.editedItem.username,
            password: this.makepass(8),
            first_name: this.editedItem.first_name,
            last_name: this.editedItem.last_name,
            is_active: true,
            date_joined: new Date(),
            //groups: [this.editedItem.groups],
            groups: [3],
            user_permissions: [],
            org_id: this.$store.getters.currentUser.org.id
          }).then(({ data }) => {
            //run role name getter
            this.getRoleNames(data);
            this.staff.push(data);
            this.close();
            resolve(data);
          });
        }).catch(({ response }) => {
          console.error(response);
        });
      }
    },
    makepass(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()[]";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    }
  }
};
</script>
